.address {
  font-size: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
}

.address p {
  margin-block: 0px!important;
}

.name {
  font-weight: bold;
}

.city {
  text-transform: uppercase;
}

@media screen and (max-width: 750px){
  .address {
    font-size: 0.84rem;
  }
}