.scrollbar {
    position: fixed;
    width: 2rem;
    height: calc(100vh - 50px);
    top: 50px;
    left: calc(100vw - 4rem);
    display: flex;
    flex-flow: column;
    justify-content: center;
    opacity: 1;
    transition: opacity 200ms linear;
}

/* .scrollbar__hidden {
    opacity: 0;
} */

@media screen and (max-width: 1350px){
    .scrollbar {
        left: calc(100vw - 2rem);
        width: 1rem;
    }
}

@media screen and (max-width: 360px){
    .scrollbar {
        left: calc(100vw - 1.5rem);
        width: 0.75rem;
    }
}