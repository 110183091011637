.goto {
  position: absolute;
  top: calc(90% - 12rem);
  left: calc(50% - 16rem);
  height: 0;
  width: 0;
}

.container {
  position: absolute;
  height: 8rem;
  width: 0rem;
  background-color: rgba(255, 255, 255);
  opacity: 0;
  transition: all 200ms linear;
}

.container div {
    width: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #121212;
    opacity: 1;
    z-index: 40;
    transition: all 200ms linear;
}

@media screen and (max-width: 1000px){
  .goto {
    top: calc(50% - 2rem);
    left: calc(50% - 8rem);
  }

  .container {
    height: 4rem;
  }

  .container div {
    font-size: 1.2rem;
  }
}