.lang-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lang-block {
  height: 100%;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-block svg {
  height: 55%;
  opacity: 0.3;
}

.lang {
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (max-width: 1000px) and (max-height: 580px) {
  .lang-icon {
    height: 3.6rem;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 750px) {
  .lang-icon {
    height: 3.6rem;
    justify-content: flex-end;
  }
}

@media (orientation: landscape) and (max-height: 320px){
  .lang-icon {
    height: 2.4rem;
  }

  .lang-block svg {
    height: 80%;
  }
}