.legal-mentions {
  background: rgba(18, 18, 18, 1);
  flex-flow: column;
  padding: 2rem;
}

.legal-mentions p {
  margin-block: 0;
  font-size: 0.92rem;
}

.bold {
  font-weight: bold;
}

@media screen and (max-height: 650px) and (orientation: landscape) {
  .legal-mentions {
    flex-flow: row;
  }

  .legal-mentions div {
    margin-right: 2rem;
  }

  @media (max-width: 1000px) {
    .legal-mentions p {
      font-size: 0.76rem;
    }
  }
}

@media screen and (max-width: 360px) {
  .legal-mentions p {
    font-size: 0.76rem;
  }
}

@media screen and (max-height: 450px) {
  .legal-mentions {
    max-width: 90%;
  }

  .legal-mentions p {
    font-size: 0.76rem;
  }
}
