.close {
  height: 0;
  width: 0;
  cursor: pointer;
  background: transparent;
  position: relative;
  top: 1.58rem;
  left: calc(100% - 3rem);
  opacity: 0;
  transform: scale3d(-0.8, 0.8, 0.8);
  transition: all 200ms linear;
}

.close-diagonal__left,
.close-diagonal__right {
  position: absolute;
  width: 0.3rem;
  height: 2rem;
}

.close-diagonal__left {
  rotate: 45deg;
}

.close-diagonal__right {
  rotate: -45deg;
}

@media screen and (max-width: 1000px) {
  .close {
    top: 0.78rem;
    left: calc(100% - 2rem);
  }

  .close-diagonal__left,
  .close-diagonal__right {
    position: absolute;
    width: 0.25rem;
    height: 1.5rem;
  }
}
