.menu {
  width: 50%;
  height: 50px;
}

.menu ul {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: 0 2rem;
  list-style: none;
  font-weight: 500;
}

.menu li {
  width: fit-content;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.menu li a {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.7);
  transition: color 200ms ease-in;
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: underline 0.15rem rgba(255, 255, 255, 0);
  text-underline-offset: 0.5rem;
  transition: text-decoration 400ms ease-in;
}

.menu li a:hover {
  color: rgba(255, 255, 255, 1);
}

.menu li a.active {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline 0.15rem rgba(255, 255, 255, 1);
}

.mobileMenu {
  width: 100vw;
  height: 100vh;
  padding: 4rem;
  position: fixed;
  top: -100vh;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
  background-color: #121212;
  opacity: 0;
  transition: opacity 400ms ease-in;
  animation: closeMenu 400ms ease-in;
}

.mobileMenu ul {
  list-style: none;
}

.mobileMenu li {
  width: 100%;
  margin: 2rem auto;
}

.mobileMenu.active {
  top: 0;
  opacity: 1;
  animation: openMenu 400ms ease-out;
}

.mobileMenu.active li {
  display: block;
}

.lang-active {
  opacity: 1;
  z-index: 30;
}

@media screen and (max-height: 360px) {
  .mobileMenu {
    padding-top: 2.4rem;
  }

  .mobileMenu li {
    margin: 1rem auto;
  }

  .mobileMenu li:last-child {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1350px) {
  .menu {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .menu {
    width: 85%;
  }
}

@keyframes openMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeMenu {
  0% {
    top: 0;
    /* opacity: 1; */
  }
  99.99% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: -100vh;
  }
}
