.map {
  width: 80%;
  margin: 2rem 0;
  height: 500px;
  max-height: 100%;
}

@media screen and (max-width: 1350px) {
  .map {
    margin: 0;
    width: 80%;
    height: 320px;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .map {
    height: 200px;
  }
}

@media screen and (max-width: 750px) {
  .map {
    height: 240px;
    width: 100%;
  }
}

@media screen and (max-width: 320px){
  .map {
    height: 200px;
  }
}

@media screen and (orientation: landscape) {
  .map {
    width: 90%;
  }
}
