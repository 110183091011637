.intro {
  transition: all 300ms linear;
  opacity: 1;
  flex-flow: column;
  text-align: center;
  max-width: 100%;
}

.slideChange {
  /* transform: scale3d(0.8, 0.8, 1); */
  opacity: 0;
}

.intro h1 {
  animation: appear 350ms linear;
}

.intro p {
  max-width: 50%;
}

.intro h1,
.intro h2,
.intro h3 {
  max-width: 50%;
}

.intro h1 {
  font-size: 4rem;
}

.intro h2 {
  font-size: 3.2rem;
  margin-block: 0;
}

.intro h3 {
  font-size: 2rem;
}

#developer {
  animation: dev 2000ms linear; /*2000 + 400 => 1400 + 600*/
}

#producer {
  animation: prod 2600ms linear;
}

#musician {
  animation: mus 3200ms linear;
}

#lieu {
  animation: lieu 3600ms ease-out;
}

.landing-text,
.journey {
  animation: remains 4000ms linear;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dev {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  70% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes prod {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  76.92% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes mus {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  81.25% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes lieu {
  0%,
  90% {
    transform: translateX(20vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes remains {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1350px) {
  .intro h1,
  .intro h2,
  .intro h3 {
    max-width: 90%;
  }

  @media (max-height: 640px) {
    .intro h1 {
      font-size: 2.8rem;
      margin-block: 1rem;
    }

    .intro h2 {
      font-size: 2rem;
    }

    .intro h3 {
      font-size: 1.2rem;
      margin-block-end: 0.4rem;
    }

    .intro p {
      max-width: 80%;
      font-size: 0.92rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  @media (orientation: landscape) {
    @media (max-height: 640px) {
      .intro h1 {
        font-size: 2.8rem;
        margin-block: 1rem;
      }

      .intro h2 {
        font-size: 2rem;
      }

      .intro h3 {
        font-size: 1.2rem;
        margin-block-end: 0.4rem;
      }

      .intro p {
        max-width: 80%;
        font-size: 0.92rem;
      }
    }

    @media (max-height: 380px) {
      .intro h1 {
        font-size: 2.4rem;
        margin-block: 0.8rem;
      }

      .intro h2 {
        font-size: 1.8rem;
      }
    }

    @media (max-height: 300px) {
      .intro p {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  @media (orientation: portrait) {
    .intro h1 {
      font-size: 3.2rem;
    }

    .intro h2 {
      font-size: 2.4rem;
    }

    .intro h3 {
      font-size: 1.6rem;
    }

    .intro p {
      max-width: 80%;
    }
  }

  @media (max-height: 400px) {
    .intro p {
      display: none;
    }
  }
}

@media screen and (max-width: 380px) {
  .intro h1 {
    font-size: 2.8rem;
    margin-block: 1rem;
  }

  .intro h2 {
    font-size: 2rem;
  }

  .intro h3 {
    font-size: 1.2rem;
    margin-block-end: 0.4rem;
  }

  .intro p {
    max-width: 80%;
    font-size: 0.92rem;
  }

  @media (max-height: 600px) {
    .intro h1 {
      font-size: 2.4rem;
    }

    .intro h2 {
      font-size: 1.8rem;
    }

    .intro h3 {
      font-size: 1rem;
    }

    .intro p {
      font-size: 0.72rem;
    }
  }
}
