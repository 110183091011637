.text,
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  max-height: calc(100vh - 50px);
}

.text {
  padding: 2rem 10rem 2rem 2rem;
  text-align: left;
  flex-flow: column;
  align-items: start;
}

.text h2 {
  width: 100%;
  font-size: 3.2rem;
}

.image {
  position: relative;
}

.image:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (max-height: 650px) {
  .text {
    width: 100%;
    padding: 2rem 4rem;
    align-items: flex-start;
  }
  
  .image {
    position: fixed;
    top: 50px;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
    opacity: 0.35;
  }
}

@media (max-width: 1350px) {
  .text {
    width: 100%;
    padding: 2rem;
    align-items: flex-start;
  }
  
  .image {
    position: fixed;
    top: 50px;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
    opacity: 0.35;
  }

  @media (max-height: 500px) {
    .text h2 {
      font-size: 2rem;
      margin-block: 1rem;
    }

    .text p {
      font-size: 0.8rem;
      margin-block: 0.5rem;
    }
  }
}

@media (max-width: 1000px) {
  @media (max-height: 580px) {
    .text h2 {
      font-size: 2rem;
      margin-block: 1rem;
    }

    .text p {
      font-size: 0.8rem;
      margin-block: 0.5rem;
    }
  }
}

@media screen and (max-width: 750px) {
  .text h2 {
    margin-block-start: 0;
    font-size: 2.4rem;
  }

  .text p {
    font-size: 0.92rem;
  }
}

@media screen and (max-width: 450px) {
  .text {
    padding: 1rem;
  }

  .text p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .text {
    padding: 0;
  }

  .text h2 {
    font-size: 2rem;
  }

  .text p {
    font-size: 0.72rem;
    margin-block: 0.4rem;
  }
}

@media screen and (max-width: 800px) and (max-height: 500px) {
  .text h2 {
    font-size: 1.8rem;
    margin-block: 0.8rem;
  }

  .text p {
    font-size: 0.72rem;
    margin-block: 0.4rem;
  }
}

@media screen and (max-width: 800px) and (max-height: 300px) {
  .text h2 {
    font-size: 1.2rem;
    margin-block: 0.4rem;
  }

  .text p {
    font-size: 0.6rem;
    margin-block: 0.32rem;
  }
}
