.burger {
  cursor: pointer;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-top: 0.8rem;
  margin-right: 1rem;
  z-index: 20;
}

.burger span {
  display: inline-flex;
  height: 0.25rem;
  width: 90%;
  margin-top: 0.2rem;
  background-color: white;
  border-radius: 10px;
  transition: all 500ms;
}

.burger-open span.burger-middleBar {
  transform: rotate(405deg);
  opacity: 0;
}

.burger-open span.burger-topBar {
  transform: rotate(315deg);
  position: relative;
  top: 0.666rem;
}

.burger-open span.burger-bottomBar {
  transform: rotate(405deg);
  position: relative;
  top: -0.666rem;
}
