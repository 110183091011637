.modal {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: calc(100vw - 6rem);
  height: calc(90vh + 50px);
  margin: auto;
  padding: 1rem;
  z-index: 3000;
  animation: slide-down 300ms ease-out forwards;
}

.content {
  position: relative;
  display: flex;
  max-height: 80%;
  max-width: 80%;
}

.content img {
  cursor: default;
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}

.closed__active {
  opacity: 1;
  transform: scale(1);
}

.goto__active {
  width: 32rem;
  opacity: 0.6;
}

.goto__active div {
    width: 32rem;
}

.overlay {
  cursor: default;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 200ms linear;
}

.overlay__active {
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1000px){
  .modal {
    width: 100vw;
    height: 100vh;
    padding: 0;
    /* padding: 2rem; */
  }

  .content {
    max-width: 100%;
    max-height: 100%;
  }

  .goto__active,
  .goto__active div {
    width: 16rem;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
