.lang-select {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #121212;
    padding-inline: 0;
    margin-block: 0;
    margin-right: 1rem;
    height: 100%;
    max-height: 100%;
    list-style: none;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -5;
    transition: opacity 300ms linear;
}

.lang-element {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.4rem 0.5rem;
    cursor: pointer;
    transition: all 200ms linear;
}

.lang-element:hover {
    transform: scale(1.4);
}