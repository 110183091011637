.spinner__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 50px);
  animation: 500ms linear 6 pulse, 4s linear 3s 1 rotate;
  z-index: 50;
}

.spinner {
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin: auto auto;
  font-size: 2rem;
  font-weight: bold;
}

.spinner__line {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.spinner__line div {
  width: 50%;
  text-align: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25%{
    transform: scale(0.9);
  }
  50%{
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(1);
      opacity: 1;
    }
    100% {
        transform: rotate(1440deg) scale(8);
        opacity: 0;
    }
  }