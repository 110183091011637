.mywork {
    opacity: 1;
    transition: all 300ms linear;
    flex-flow: row;
    padding: 2rem 4rem 2rem 2rem;
    text-align: left;
}

.slideChange {
    opacity: 0;
    /* Commented out due to koherence with About section, where the transform caused problems */
    /* transform: scale3d(0.8,0.8,1); */
}

@media screen and (max-width: 1000px) and (orientation: portrait) {
    .mywork {
        flex-flow: column;
        flex-direction: column-reverse;
    }
  }