.topBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  max-width: 100vw;
  height: 50px;
  background-color: #121212;
  display: flex;
  justify-content: space-between;
  opacity: 1;
}

@media screen and (max-width: 1000px) and (max-height: 580px) {
  .topBar {
    background-color: transparent;
  }
}

@media screen and (max-width: 750px) {
  .topBar {
    background-color: transparent;
  }
}