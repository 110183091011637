.wrapper {
  position: relative;
  margin-top: 50px; /* testing-value */
  width: 100%;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 50px);
}

@media screen and (max-width: 1000px) and (max-height: 580px) {
  .wrapper {
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    margin-top: 0;
  }
}

@media screen and (max-width: 750px) {
  .wrapper {
    margin-top: 0;
  }
}
