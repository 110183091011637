.icon {
  display: flex;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: white;
  margin: 0 2rem;
}

.icon a,
.icon span {
  display: block;
  margin: auto;
}

.icon svg {
  color: #121212;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 750px) {
  .icon {
    width: 3rem;
    height: 3rem;
    margin: 0 1rem 0 0;
  }

  .icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .icon {
    width: 3rem;
    height: 3rem;
    margin: 0 1rem 0 0;
  }

  .icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
