.icon {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0.4rem 0;
    background-color: #121212;
}

.icon img {
    max-width: 100%;
}

.dot {
    text-align: center;
    /* background-color: white; */
    width: 0.5rem;
    height: 0.5rem;
    border: 0.12rem solid white;
    border-radius: 50%;
    transition: all 300ms linear;
}

.active {
    background-color: white;
    transform: scale(1.5);
}

@media screen and (max-width: 320px){
    .icon {
        margin: 0.3rem 0
    }

    .dot {
        width: 0.4rem;
        height: 0.4rem;
        border-width: 0.1rem;
    }
}