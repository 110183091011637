.logo {
  margin-left: 2rem;
  z-index: 20;
}

.logo img {
  max-height: 100%;
  max-width: 4rem;
}

@media screen and (max-height: 300px) and (max-width: 600px) {
  .logo {
    visibility: hidden;
  }
}

@media screen and (max-height: 450px) and (max-width: 500px) {
  .logo {
    visibility: hidden;
  }
}

@media screen and (max-height: 650px) and (max-width: 400px) {
  .logo {
    visibility: hidden;
  }
}
