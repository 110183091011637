.section {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) and (max-height: 580px) {
  .section {
    height: calc(
      100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    );
  }
}

@media screen and (max-width: 750px) {
  .section {
    height: calc(
      100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    );
  }
}
