.text {
  padding: 2rem 10rem 2rem 2rem;
  text-align: left;
  flex-flow: column;
  align-items: left;
  width: 50%;
}

.image__container {
  width: 50%;
}

.text h2 {
  width: 100%;
  font-size: 3.2rem;
}

.image__container img {
  max-width: 100%;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .text {
    width: 100%;
    padding: 2rem;
  }
  .image__container {
    width: auto;
    padding: 2rem;
  }

  @media (orientation: portrait) {
    .image__container {
      max-height: 50vh;
      max-width: 80%;
    }
  }

  @media (orientation: landscape) {
    .text h2 {
      font-size: 2rem;
    }

    .text p {
      font-size: 0.8rem;
    }

    .image__container {
      max-width: 50%;
      padding: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  @media (orientation: portrait) {
    .text h2 {
      margin-block-start: 0;
      font-size: 2.4rem;
    }
    .text p {
      font-size: 0.92rem;
    }

    .image__container {
      padding: 0;
    }
  }

  @media (orientation: landscape) {
    .text {
      padding: 1rem;
    }

    .text h2 {
      font-size: 1.2rem;
      margin-block: 0.4rem;
    }

    .text p {
      font-size: 0.6rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .text {
    padding: 0;
    margin-bottom: 2rem;
  }

  .text p {
    /* margin-block: 0.5rem; */
    font-size: 0.8rem;
  }

  .image__container {
    max-width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .text {
    padding: 0;
  }

  .text h2 {
    font-size: 2rem;
  }

  .text p {
    font-size: 0.72rem;
    margin-block: 0.4rem;
  }
}
