.contact {
  opacity: 1;
  transition: all 300ms linear;
  flex-flow: column;
  text-align: center;
  padding: 2rem;
}

.text,
.map,
.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-width: 40%;
  max-width: 50%;
}

.slideChange {
  opacity: 0;
  /* transform: scale3d(0.8, 0.8, 1); */
}

.text {
  padding: 2rem;
  max-height: calc(100% / 3);
}

.contact-info {
  flex-flow: row;
  margin: 2rem;
  max-height: calc(100% / 6);
  max-width: 90%;
  flex-wrap: wrap;
}

.map {
  max-height: calc(100% / 2);
}

.text h2 {
  width: 100%;
  font-size: 3.2rem;
}

.legal-mentions {
  min-width: 100%;
  
}

.legal-mentions p {
  margin-block: 0;
  margin-top: 0.5rem;
  text-decoration: underline;
  font-size: 0.72rem;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  .contact {
    flex-flow: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
  }

  .text {
    max-width: 90%;
    padding: 2rem 4rem;
  }

  .contact-info {
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    max-width: 50%;
    max-height: calc(100% / 3 * 2);
    margin: 0;
  }

  .map {
    min-width: 50%;
    /* max-width: calc(100%-4rem); */
    max-height: calc(100% / 3 * 2);
    margin: 0;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .text {
    font-size: 0.8rem;
  }

  .text h2 {
    font-size: 2rem;
  }

  .text p {
    margin-block: 0.5rem;
  }
}

@media screen and (max-width: 750px) and (orientation: portrait) {
  .contact {
    padding: 0;
    flex-flow: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .text {
    padding: 0;
    margin-bottom: 1rem;
  }

  .contact-info {
    flex-flow: row;
    max-width: 90%;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .text h2 {
    margin-block-start: 0;
    font-size: 2.4rem;
  }

  .map {
    margin: 1rem 0 0 0;
    max-width: calc(100% - 6rem);
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .text p {
    font-size: 0.8rem;
  }

  .text h2 {
    font-size: 2rem;
    margin-block-start: 0;
  }

  @media (max-height: 640px) {
    .map {
      display: none;
    }
  }
}

@media screen and (max-height: 320px) and (orientation: landscape) {
  .text p {
    display: none;
  }

  .text h2 {
    font-size: 1.6rem;
  }

  .text {
    width: 100%;
    padding: 0;
  }
}
