.about {
  opacity: 1;
  transition: all 300ms linear;
  flex-flow: row;
}

.slideChange {
  opacity: 0;
  /* Doesn't work as intended, background image moves in undesirable fashion. For now comment out, later repair or delete */
  /* transform: scale3d(0.8, 0.8, 1); */
}
