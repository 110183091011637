.contact-box {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.contact-box p {
  margin-block: 0.5rem;
}

@media screen and (max-width: 1350px) {
  .contact-box {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .contact-box {
    margin: 0 1rem 1rem 0;
  }

  .contact-box p {
    margin-block: 0.25rem;
    font-size: 0.84rem;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .contact-box {
    margin: 0 1rem 1rem 0;
  }

  .contact-box p {
    margin-block: 0.25rem;
    font-size: 0.84rem;
  }
}
